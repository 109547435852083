import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  data() {
    return {
      courseId: '',  
      
    };
  },
  actions: {
    setCourse(ccourseId) {
      console.log("incoming course "+ ccourseId);
      this.courseId=  ccourseId
    },
    fetchAllSuggestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/hr/admin/suggestions',  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSuggestion(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .put('/hr/admin/updateSuggestion', queryParams )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSuggestion(ctx,  queryParams ) {
      var id=queryParams.id;
      console.log("  course subscribers Fetching --" + id);
      return new Promise((resolve, reject) => {
        axios
          .get(`/hr/admin/suggestions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFormStatuses(ctx,  queryParams ) {
      var id=queryParams.id;
      console.log("  course subscribers Fetching --" + id);
      return new Promise((resolve, reject) => {
        axios
          .get(`/hr/admin/formstatuses`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/auth/f/cm//getmemberdetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
     
    
    


  },
}
